<template>
  <div>
    <BaseImg title="frequently asked questions" bgName="bg_faq.png">
      <div class="subTitle">
        We are always happy to help answer any questions you have just let us
        know and we will get back to you as soon as possible.
      </div>
    </BaseImg>
    <div class="faq-bottom">
      <BaseFAQQ
        question="Get in How long will it take for the money to
      arrive in my account?"
      ></BaseFAQQ>
      <BaseFAQA
        class="marginTop16"
        text="It depends on the country you are
      transferring to or from but usually we aim for T+1, which
      means it’ll arrive in your designated account on the next
      day."
      ></BaseFAQA>
      <BaseFAQQ
        class="marginTop48"
        question="Can I still use
      United AU if I don’t have an overseas account?"
      ></BaseFAQQ>
      <BaseFAQA
        class="marginTop16"
        text="If you are sending
      money from overseas to AUS, then you will only need a receiving account in AUS.</br>
      However, if you are sending money overseas you must
      provide us with a bank account in that overseas destination."
      ></BaseFAQA>
      <BaseFAQQ
        class="marginTop48"
        question="What can count as
      a proof of address (POA)?"
      ></BaseFAQQ>
      <BaseFAQA
        class="marginTop16"
        text="MUST select ONE option from these:</br>
      • A utility bill, council rates bill issued to the
      individual that is not more than 3 months old showing the
      address and name of the individual</br>
      • A statement from Land Tax Notice from state land tax office issued to the individual that is
      not more than 3 months old showing the address and name
      of the individual</br>
      • A tenancy agreement for the place the person is
      currently renting</br>
      • A letter from an electoral office</br>
      • An insurance company policy or a letter from the person’s superannuation account provider</br>
      • Court document</br>
      • Car registration notification/demand addressed to person</br>
      • Letter from educational institution addressed to the
      person (must be letterhead paper and signed by person in
      authority confirming residential address)</br>
      • A letter/notification from Workcover, local council or government organization"
      ></BaseFAQA>
      <BaseFAQQ
        class="marginTop48"
        question="If I’m not based in Auckland, Sydney
      or Beijing can I still open an United AU account?"
      ></BaseFAQQ>
      <BaseFAQA
        class="marginTop16"
        text="Yes, we are able to
      conduct our account opening process online."
      ></BaseFAQA>
      <BaseFAQQ class="marginTop48" question="Are there any fees?"></BaseFAQQ>
      <BaseFAQA
        class="marginTop16"
        text="No, the price we give
      you is all you need to pay."
      ></BaseFAQA>
    </div>
  </div>
</template>

<script>
import BaseImg from '@/components/BaseImg.vue'
import BaseFAQQ from '@/components/BaseFAQQ.vue'
import BaseFAQA from '@/components/BaseFAQA.vue'

export default {
  data () {
    return {
      shortcode: process.env.VUE_APP_BASE_SHORTCODE
    }
  },
  components: {
    BaseImg,
    BaseFAQQ,
    BaseFAQA
  }
}
</script>

<style scoped>
.subTitle {
  width: 862px;
  height: 64px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  line-height: 32px;
  margin: 20px auto 0;
  text-align: center;
  text-shadow: 2px 2px gray;
}
.faq-bottom {
  width: 1440px;
  margin: 0 auto;
  padding: 5rem
}
.marginTop16 {
  margin-top: 15px;
}
.marginTop48 {
  margin-top: 48px;
}
@media only screen and (max-width: 600px){
.subTitle {
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0 5vw;
  line-height: 1.5rem;
  margin: 3vw auto 0;
  text-align: center;
}
.faq-bottom {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5vw;
}
.marginTop16 {
  margin-top: 3vw;
}
.marginTop48 {
  margin-top: 5vw;
}
}
</style>
